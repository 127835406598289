import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSuspensionPoint from 'src/components/edit-suspension-point/edit-suspension-point';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditViolationPage = () => {
  return (
    <Router basepath="/players/:id/suspensions/violations">
      <EditViolationRoute path="/:violationId" />
    </Router>
  );
};

interface EditViolationRouteProps extends RouteComponentProps {
  id?: string;
  violationId?: string;
}

const EditViolationRoute: React.FC<EditViolationRouteProps> = ({ id, violationId }) => {
  return (
    <Layout>
      <SEO title="Edit violation" />
      <PrivateRoute>
        <EditSuspensionPoint id={id} violationId={violationId} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditViolationPage;
