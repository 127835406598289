import dayjs from 'dayjs';

/**
 * Due to a discrepancy between how issuedAt times are being saved in Tournament Desk
 * and Control Centre, the two following methods ensure that those generated by TDesk
 * are represented in the local browser timezone, whereas those created in CC are UTC
 * dates with 0'd time components. This should be addressed within both TD and CC to align
 * the two.
 */
export const resolveViolationDate = (v, dateFieldName) => {
  const date = v[dateFieldName];
  if (v.isTournamentDesk) return dayjs(date).local().format('ll');
  return dayjs.utc(date).format('ll');
};

export const violationDateUTCStripTime = (v) => {
  if (!v?.violationDate) return;
  const violationDateObject = new Date(v.violationDate);
  if (v.isTournamentDesk) {
    return new Date(
      Date.UTC(violationDateObject.getFullYear(), violationDateObject.getMonth(), violationDateObject.getDate()),
    );
  }
  return new Date(
    Date.UTC(violationDateObject.getUTCFullYear(), violationDateObject.getUTCMonth(), violationDateObject.getUTCDate()),
  );
};
