// extracted by mini-css-extract-plugin
export var bodyBase = "edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodyLarge = "edit-suspension-point-module--body-large--8a69f edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodyLargeBold = "edit-suspension-point-module--body-large-bold--73e05 edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodyRegular = "edit-suspension-point-module--body-regular--e59ab edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodyRegularBold = "edit-suspension-point-module--body-regular-bold--690aa edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodySmall = "edit-suspension-point-module--body-small--32f4e edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var bodySmallBold = "edit-suspension-point-module--body-small-bold--43d91 edit-suspension-point-module--body-base--39fdd edit-suspension-point-module--site-font--7b68c";
export var borderTop = "edit-suspension-point-module--border-top--748f1";
export var breakWordContainer = "edit-suspension-point-module--break-word-container--1079f";
export var buttonIconBase = "edit-suspension-point-module--button-icon-base--c4e57";
export var clickLink = "edit-suspension-point-module--click-link--a363c";
export var datepickerContainer = "edit-suspension-point-module--datepicker-container--f4cac";
export var dropdownBase = "edit-suspension-point-module--dropdown-base--55b0f";
export var dropdownSelectBase = "edit-suspension-point-module--dropdown-select-base--7cda8 edit-suspension-point-module--text-input--80aa7";
export var flexCol = "edit-suspension-point-module--flex-col--b6432";
export var formErrorMessage = "edit-suspension-point-module--form-error-message--88ea7";
export var h3 = "edit-suspension-point-module--h3--90566";
export var h4 = "edit-suspension-point-module--h4--73ce3";
export var hoverLink = "edit-suspension-point-module--hover-link--de0ef";
export var hoverRow = "edit-suspension-point-module--hover-row--fab47";
export var icon = "edit-suspension-point-module--icon--9b506";
export var infoLabelOverride = "edit-suspension-point-module--info-label-override--101ca";
export var membershipContainer = "edit-suspension-point-module--membership-container--0c326 edit-suspension-point-module--flex-col--b6432 edit-suspension-point-module--primary-border--0b2b3";
export var membershipHeader = "edit-suspension-point-module--membership-header--cbaa5";
export var membershipHeading = "edit-suspension-point-module--membership-heading--c3289";
export var membershipLabel = "edit-suspension-point-module--membership-label--1c5c4";
export var moreFiltersBorderClass = "edit-suspension-point-module--more-filters-border-class--448b7";
export var padding = "edit-suspension-point-module--padding--647b8";
export var pageBg = "edit-suspension-point-module--page-bg--7a032";
export var pointer = "edit-suspension-point-module--pointer--ecfd3";
export var points = "edit-suspension-point-module--points--0fd44";
export var pointsField = "edit-suspension-point-module--points-field--f9210 edit-suspension-point-module--primary-border--0b2b3";
export var primaryBorder = "edit-suspension-point-module--primary-border--0b2b3";
export var shadowBoxLight = "edit-suspension-point-module--shadow-box-light--45060";
export var siteFont = "edit-suspension-point-module--site-font--7b68c";
export var slideDownAndFade = "edit-suspension-point-module--slideDownAndFade--2dc4f";
export var slideLeftAndFade = "edit-suspension-point-module--slideLeftAndFade--14fe7";
export var slideRightAndFade = "edit-suspension-point-module--slideRightAndFade--1fe34";
export var slideUpAndFade = "edit-suspension-point-module--slideUpAndFade--60cee";
export var statusDecoration = "edit-suspension-point-module--status-decoration--39983";
export var suspensionPointEvent = "edit-suspension-point-module--suspension-point-event--0b825 edit-suspension-point-module--primary-border--0b2b3";
export var tdComments = "edit-suspension-point-module--td-comments--ca25a";
export var textAreaContainer = "edit-suspension-point-module--text-area-container--49b32";
export var textInput = "edit-suspension-point-module--text-input--80aa7";
export var textInverted = "edit-suspension-point-module--text-inverted--24797";
export var textMediumDark = "edit-suspension-point-module--text-medium-dark--d1743";
export var tooltipFont = "edit-suspension-point-module--tooltipFont--b2cdb";
export var unstyledButton = "edit-suspension-point-module--unstyled-button--2e343";